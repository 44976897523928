"use client";

import { Box, Flex, chakra } from "@chakra-ui/react";
import { SkipNavContent, SkipNavLink } from "@chakra-ui/skip-nav";
import { usePathname } from "next/navigation";
import * as React from "react";
import { useEffect } from "react";

import Header from "~/components/header";
import SEO from "~/components/seo";
import { TableOfContent } from "~/components/table-of-content";
import { t } from "~/utils/i18n";

function useHeadingFocusOnRouteChange() {
  const pathname = usePathname();

  useEffect(() => {
    const [heading] = Array.from(document.getElementsByTagName("h1"));
    heading?.focus();
  }, [pathname]);
}

export type Heading = {
  level: "h2" | "h3";
  text: string;
  id: string;
};

type PageContainerProps = {
  frontmatter: {
    slug?: string;
    title: string;
    description?: string;
    version?: string;
    headings?: Heading[];
    icon?: string;
  };
  hideToc?: boolean;
  maxWidth?: string;
  children: React.ReactNode;
  sidebar?: React.ReactElement;
  pagination?: React.ReactElement;
};

export function PageContainer(props: PageContainerProps) {
  const {
    frontmatter,
    children,
    sidebar,
    pagination,
    hideToc,
    maxWidth = "48rem",
  } = props;

  useHeadingFocusOnRouteChange();

  if (!frontmatter) {
    return <></>;
  }

  const { title, description, headings = [], icon } = frontmatter;

  return (
    <>
      <SEO title={title} description={description} />

      <SkipNavLink zIndex={20}>
        {t("component.page-container.skip-to-content")}
      </SkipNavLink>

      <Header />

      <Box
        as="main"
        className="main-content"
        w="full"
        maxW="8xl"
        mx="auto"
        paddingTop="73px"
      >
        <Box display={{ md: "flex" }}>
          {sidebar || null}

          <SkipNavContent />

          <Box flex="1" minW="0">
            <Box
              id="content"
              px={{ base: "4", lg: "14" }}
              mx="auto"
              minH="76vh"
            >
              <Flex width="inherit">
                <Box minW="0" flex="auto" pt="8">
                  <Box maxW={maxWidth}>
                    <Flex>
                      {icon && (
                        <img
                          src={icon}
                          style={{ width: "3rem", paddingRight: "1rem" }}
                        />
                      )}

                      <chakra.h1
                        style={{ marginBottom: 0 }}
                        tabIndex={-1}
                        outline={0}
                        apply="mdx.h1"
                      >
                        {title}
                      </chakra.h1>
                    </Flex>

                    {children}
                    <Box mt="40px">{pagination || null}</Box>
                  </Box>
                </Box>

                {!hideToc && (
                  <TableOfContent
                    visibility={headings.length === 0 ? "hidden" : "initial"}
                    headings={headings}
                  />
                )}
              </Flex>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
