export type RouteItem = {
  title: string;
  path?: string;
  heading?: boolean;
  routes?: RouteItem[];
};

export type RouteContext = {
  parent?: RouteItem;
  prevRoute?: RouteItem;
  nextRoute?: RouteItem;
  route?: RouteItem;
};

export const flattenRoutes = (routes: RouteItem[]): RouteItem[] => {
  return routes.flatMap((route) => [
    route,
    ...flattenRoutes(route.routes ?? []),
  ]);
};

/**
 * Returns the siblings of a specific route (that is the previous and next routes).
 */
export const getRouteContext = (
  route: RouteItem,
  routes: RouteItem[]
): RouteContext => {
  const allRoutes = flattenRoutes(routes);
  const routesWithPath = allRoutes.filter((r) => r.path != null);
  const routeIndex = routesWithPath.indexOf(route);

  return {
    prevRoute: routesWithPath[routeIndex - 1],
    nextRoute: routesWithPath[routeIndex + 1],
  };
};
