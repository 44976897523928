import { Doc } from "contentlayer/generated";
import { ReactNode } from "react";

import { PageContainer } from "~/components/page-container";
import { Pagination } from "~/components/pagination";
import { Sidebar } from "~/components/sidebar/sidebar";
import { routes as sidebarRoutes } from "~/configs/sidebar-routes";
import {
  flattenRoutes,
  getRouteContext,
  RouteItem,
} from "~/utils/get-route-context";

type MDXLayoutProps = {
  frontmatter: Doc["frontMatter"];
  children: ReactNode;
  hideToc?: boolean;
  maxWidth?: string;
};

export function MDXLayout(props: MDXLayoutProps) {
  const { frontmatter, children, hideToc, maxWidth } = props;

  const pathWithoutHash = removeFromLast(frontmatter.slug, "#");
  const sidebarRoutePath = frontmatter.slug.includes("/components/")
    ? `${pathWithoutHash.split("/").slice(0, -1).join("/")}/usage`
    : pathWithoutHash;

  const route = findRouteByPath(sidebarRoutePath, sidebarRoutes);
  const routeContext = getRouteContext(route, sidebarRoutes);

  return (
    <PageContainer
      hideToc={hideToc}
      maxWidth={maxWidth}
      frontmatter={frontmatter}
      sidebar={<Sidebar routes={sidebarRoutes} />}
      pagination={
        <Pagination
          next={routeContext.nextRoute}
          previous={routeContext.prevRoute}
        />
      }
    >
      {children}
    </PageContainer>
  );
}

function removeFromLast(path: string, key: string) {
  const index = path.lastIndexOf(key);
  return index === -1 ? path : path.substring(0, index);
}

function findRouteByPath(path: string, routes: RouteItem[]): RouteItem {
  const route = flattenRoutes(routes).find(
    (r) => r.path && removeFromLast(r.path, ".") === path
  );

  if (route == null) {
    throw new Error(`Route not found: ${path}`);
  }

  return route;
}
