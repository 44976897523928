import * as Chakra from "@chakra-ui/react";
import { AlertProps, BoxProps, HTMLChakraProps } from "@chakra-ui/react";
import NextImage, { ImageProps } from "next/image";
import * as React from "react";

import { CodeBlock, CodeBlockProps } from "./codeblock/codeblock";

import { Anchor } from "~/components/mdx-components/anchor";
import { GuideLinkBox } from "~/components/mdx-components/guide-link-box";
import { InlineCode } from "~/components/mdx-components/inline-code";
import {
  LinkedHeading,
  LinkedHeadingProps,
} from "~/components/mdx-components/linked-heading";
import { Pre, PreProps } from "~/components/mdx-components/pre";
import { Table, Td, Th, Thead, Tbody } from "~/components/mdx-components/table";

const { Alert, AspectRatio, Box, chakra, Kbd, Link } = Chakra;

const Image: React.FunctionComponent<any> = (props: ImageProps) => (
  <Box my="5">
    <NextImage
      layout="responsive"
      width={700}
      height={400}
      objectFit="contain"
      {...props}
    />
  </Box>
);

export const MDXComponents = {
  ...(Chakra as any),
  Image,
  LinkedImage: ({ href, ...props }: ImageProps & { href: string }) => (
    <Link display="block" my="10" href={href} isExternal>
      <MDXComponents.Image {...props} />
    </Link>
  ),
  h1: (props: LinkedHeadingProps) => <chakra.h1 apply="mdx.h1" {...props} />,
  h2: (props: LinkedHeadingProps) => (
    <LinkedHeading apply="mdx.h2" {...props} />
  ),
  h3: (props: LinkedHeadingProps) => (
    <LinkedHeading as="h3" apply="mdx.h3" {...props} />
  ),
  h4: (props: LinkedHeadingProps) => (
    <LinkedHeading as="h4" apply="mdx.h4" {...props} />
  ),
  hr: (props: HTMLChakraProps<"hr">) => <chakra.hr apply="mdx.hr" {...props} />,
  strong: (props: BoxProps) => (
    <Box as="strong" fontWeight="semibold" {...props} />
  ),
  code: InlineCode,
  pre: (props: PreProps | CodeBlockProps) => {
    if (typeof props.children === "string") {
      return <Pre {...props} />;
    }

    return <CodeBlock {...(props as CodeBlockProps)} />;
  },
  kbd: Kbd,
  br: ({ reset, ...props }: BoxProps & { reset: boolean }) => (
    <Box
      as={reset ? "br" : undefined}
      height={reset ? undefined : "24px"}
      {...props}
    />
  ),
  table: Table,
  th: Th,
  td: Td,
  thead: Thead,
  tbody: Tbody,
  a: Anchor,
  p: (props: HTMLChakraProps<"p">) => <chakra.p apply="mdx.p" {...props} />,
  ul: (props: HTMLChakraProps<"ul">) => <chakra.ul apply="mdx.ul" {...props} />,
  ol: (props: HTMLChakraProps<"ol">) => <chakra.ol apply="mdx.ul" {...props} />,
  li: (props: HTMLChakraProps<"li">) => <chakra.li pb="4px" {...props} />,
  blockquote: (props: AlertProps) => (
    <Alert
      mt="4"
      role="none"
      status="warning"
      variant="left-accent"
      as="blockquote"
      rounded="4px"
      my="1.5rem"
      {...props}
    />
  ),
  AspectRatio,
  GuideLinkBox,
};
