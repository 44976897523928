import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Link,
  LinkProps,
  SimpleGrid,
  SimpleGridProps,
  Text,
} from "@chakra-ui/react";
import NextLink from "next/link";
import React, { ReactNode } from "react";

import { RouteItem } from "~/utils/get-route-context";

export type PaginationLinkProps = LinkProps & {
  label: string;
  href: string;
  children: ReactNode;
};

export const PaginationLink = (props: PaginationLinkProps) => {
  const { label, href, children, ...rest } = props;

  return (
    <Link
      as={NextLink}
      href={href}
      _hover={{
        textDecor: "none",
      }}
      flex="1"
      borderRadius="md"
      {...rest}
    >
      <Text fontSize="sm" px="2">
        {label}
      </Text>
      <Text mt="1" fontSize="lg" fontWeight="bold" color="blue.400">
        {children}
      </Text>
    </Link>
  );
};

export type PaginationProps = SimpleGridProps & {
  previous: RouteItem | undefined;
  next: RouteItem | undefined;
};

export function Pagination({ previous, next, ...rest }: PaginationProps) {
  return (
    <SimpleGrid
      as="nav"
      aria-label="Pagination"
      spacing="40px"
      my="64px"
      columns={2}
      {...rest}
    >
      {previous ? (
        <PaginationLink
          textAlign="left"
          label="Previous"
          href={previous.path!}
          rel="prev"
        >
          <ChevronLeftIcon mr="1" fontSize="1.2em" />
          {previous.title}
        </PaginationLink>
      ) : (
        <div />
      )}
      {next ? (
        <PaginationLink
          textAlign="right"
          label="Next"
          href={next.path!}
          rel="next"
        >
          {next.title}
          <ChevronRightIcon ml="1" fontSize="1.2em" />
        </PaginationLink>
      ) : (
        <div />
      )}
    </SimpleGrid>
  );
}
