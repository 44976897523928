import dynamic from "next/dynamic";
import * as React from "react";

import { MDXLayout } from "./mdx";

const ComponentLayout = dynamic(() => import("./component"));

export function Layout(props: {
  children: React.ReactNode;
  frontMatter: any;
  hideToc?: boolean;
  maxWidth?: string;
}) {
  const { frontMatter, ...rest } = props;
  const slug = frontMatter?.slug;

  const layoutMap = {
    components: <ComponentLayout frontmatter={frontMatter} {...rest} />,
    default: <MDXLayout frontmatter={frontMatter} {...rest} />,
  };

  const layout = Object.entries(layoutMap).find(([path]) => {
    return slug?.startsWith(`/${path}`);
  });

  if (!layout) {
    return layoutMap.default;
  }

  return layout[1];
}
